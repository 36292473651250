import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import '../css/aboutMakoto.css'


const AboutMakotoPage = () => {
    return (
        <Layout pageTitle='About Makoto' subTitle='Makoto Nishimura' description="Learn about your shamisen teacher in Tokyo, Makoto Nishimura.">
            <div className='sub-wrapper about-makoto-page'>
                <div className='section-content'>
                    <figure>
                        <StaticImage
                            className='makoto-img'
                            alt='Makoto Nishimura in a deep blue kimono playing a shamisen'
                            src='../images/makoto_nishimura.webp'
                        />
                        <figcaption>
                            Makoto Nishimura
                        </figcaption>
                    </figure>
                    <p>
                        Makoto has been playing the shamisen for over 50 years and graduated from Tokyo University of Fine Arts.
                    </p>
                    <p>
                        She started teaching shamisen by chance but now finds that teaching foreign students is a passion that encourages her to keep playing music to this day.
                        Apart from shamisen, Makoto also enjoys going to libraries and reading about Edo to Meiji history. She also goes on one night trips to libraries in Tokyo and elsewhere to discover the unique books they have.
                    </p>
                </div>
                <hr />
                <figure className='janet-quote'>
                    <blockquote>
                        <p>
                            Nishimura's goal in her teaching is twofold: to spread shamisen music in the world and enjoy playing music. The former is difficult, as the shamisen is rarely seen in Japan, let alone abroad. Nishimura plays nagauta music, a style still heard in the Kabuki theater today. However, it is rapidly disappearing due to expense and apathy. Among foreigners, Nishimura has found more interest in traditional Japan than among the Japanese themselves. "It's very ironic,"she smiles, relishing the paradox." My foreign students are more Japanese than most Japanese."
                        </p>
                        <p>
                            The second of Nishimura's goals - to enjoy music - is more complicated than it sounds. "Japanese people don't enjoy music. It's all about being perfect and not making mistakes. I like to enjoy music with my students. Even professional musicians don't play music together. They often practice alone and get together only once to rehearse before a concert. "Playing music together may be a relic of bygone days when this music was popular." The people of late Edo were very sophisticated. The public baths all had community centers on the second floor where people could sing or play shamisen. It was part of socializing. If they couldn't play something it was shameful. Of course, there was no radio or TV. Nishimura has created her own version of Edo with her students, assembling them often for concerts and group rehearsals at her house.
                        </p>
                        <p>
                            Nishimura was not always so confident and driven. She became more serious about expanding nagauta music after meeting Dr.William P. Malm, an American scholar and expert on Japanese music, in 1995. "That year, I was invited to Australia by student to play at Melbourne University's music department. I only performed, I didn't explain so much, so they didn't understand it very well. Then I found out about Bill Malm from another student, and I discovered that he had studied with my teacher. So I wrote him a fan letter. "Since then, Malm has visited Nishimura and her group on visits to Tokyo. I felt more serious and inspired after I met him. Like I had a foundation for teaching." She now uses Malm's books and articles to accompany her instruction, and often explains basic musical concepts at performances so the audience can enjoy it more through understanding.
                        </p>
                        <p>
                            Nishimura's own focus has turned more academic as of late. Last fall, she went to Northwestern University in Chicago to teach a week-long shamisen workshop in a Japanese theater class being taught by one of her former students. "Northwestern was invigorating because it was pure music. There weren't the distracting personal dramas that I have with students here in Tokyo. We could all just concentrate on the music. Paradise. Nishimura would like to increase her experiences teaching abroad, but knows that her main work is in Japan.
                        </p>
                        <p>
                            Unfortunately, there is little support for Nishimura's efforts to resuscitate nagauta music. Although she has studied shamisen for 36 years, graduating with a degree from the prestigious Tokyo University of Fine Arts, she is an amateur. "The amateur musician is not respected. No matter how skilled the musician, it will always be a hobby. Non-musicians don't understand. They soon judge and criticize. They think we are wasting our time and should do something to make money instead." The only thing Nishimura is interested in making is music, and by doing so will continue to provide access to the mysteries of Japan and its music to those at home and abroad for a long time to come.
                        </p>
                    </blockquote>
                    <figcaption>—Janet Pocorobba</figcaption>
                </figure>
            </div>
        </Layout>
    )
}

export default AboutMakotoPage